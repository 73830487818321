import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Switch, TextField } from "@mui/material";
import { closeEditVendorModal, handleUpdateVendor } from './vendorSlice';
import { Close } from "@mui/icons-material";
import styles from './Vendor.module.css';
import "react-datepicker/dist/react-datepicker.css";
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

export function EditVendor(props) {
  const { vendor } = props;
  const dispatch = useDispatch();
  const [vendorState, _setVendorState] = useStateWithCallbackLazy({
    form: {
      ...vendor
    },
    error: false,
    init: false,
    errorMessage: ""
  });
  const [invalidUrl, setInvalidUrl] = useState(false);
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const vendorStateRef = useRef(vendorState);

  const setVendorState = data => {
    vendorStateRef.current = data;
    _setVendorState(data);
  };

  useEffect(() => {
    if (vendor && !vendorState.init) {
      const {...vendorForm} = {...vendor};
      setVendorState({
        ...vendorState,
        init: true,
        form: {
          ...vendorForm
        }
      });
    }
  });

  const onPickupNotifcationChange = event => {
    setVendorState({
      ...vendorState,
      form: {
        ...vendorState.form,
        pickupNotification: event.target.checked
      }
    })
  }

  const handleCallbackUrlChange = event => {
    setVendorState({
      ...vendorState,
      form: {
        ...vendorState.form,
        callbackUrl: event.target.value
      }
    })

    // URL regex validation
    const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(:\d+)?(\/[\w-]*)*\/?(\?.*)?(#.*)?$/;
    if (urlRegex.test(event.target.value)) {
      setInvalidUrl(false);
    } else {
      setInvalidUrl(true);
    }
  }

  const submit = async (props) => {
    let error = false;
    if (!error) {
      await withDisable(
        () => dispatch(handleUpdateVendor(vendorState.form, vendorState.form.id)),
        (err) => {
          setVendorState({...vendorState, error: true, errorMessage: err.message});
        }
      );
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={`${styles.modalContainer} scrollbar-hidden`}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeEditVendorModal())}/>
          <h3 className={styles.subtitle}>Edit Vendor<em style={{fontSize: 12}}></em></h3>
          <Fade in={vendorState.error}>
            <p className={styles.errorMessage}>{vendorState.errorMessage}</p>
          </Fade>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className={styles.textInputLabel}>Pickup Notification</p>
              <Grid component="label" container alignItems="center" spacing={2}>
                  <Grid item>Disable</Grid>
                  <Grid item>
                      <Switch
                          color="primary"
                          checked={vendorState.form.pickupNotification === true}
                          onChange={onPickupNotifcationChange}
                      />
                  </Grid>
                  <Grid item>Enable</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid component="label" alignItems="center">
                <TextField
                  id={`callbackUrl`}
                  label={`Callback Url`}
                  onChange={handleCallbackUrlChange}
                  value={vendorState.form.callbackUrl}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={invalidUrl}
                  helperText={invalidUrl ? "Please enter a valid URL" : ""}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={() => submit(props)}
                disabled={invalidUrl || isDisabled}
                className={`bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md ${
                  (invalidUrl || isDisabled) ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                }`}
              >
                Save
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
