import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  CircularProgress
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { selectState, setSelectedFilter, showFilterModal, setErrorMessage } from './rentingSlice';
import { getAllParkingAreas } from "../parkingArea/parkingAreaAPI";
import SearchableSelect from "react-select";

const FilterModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const [parkingAreaLoading, setParkingAreaLoading] = useState(false);

  const selectedFilter = state.selectedFilter;
  const [parkingAreaName, setParkingAreaName] = useState(selectedFilter.parkingAreaName)
  const [parkingAreas, setParkingAreas] = useState([]);

  useEffect(() => {
    const handleGetParkingAreas = async () => {
      setParkingAreaLoading(true);
      try {
        const response = await getAllParkingAreas(0, 999999, "desc", "createdAt");
        const { content } = response.data;
        setParkingAreas(content);
      } catch (error) {
        dispatch(setErrorMessage("Failed to fetch parking areas."));
      } finally {
        setParkingAreaLoading(false);
      }
    };
    handleGetParkingAreas();
  }, [dispatch]);

  const submit = () => {
    try {
      dispatch(setSelectedFilter({
        parkingAreaName
      }));
      dispatch(showFilterModal(false));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="filter-renting-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] h-[50%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showFilterModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Filter Rentings</p>
          <Grid container spacing={2}>
            <Grid item xs={12} zIndex={999}>
                <p className="my-[5px] text-[#929292] text-[14px]">
                    Requested Parking Area
                </p>
                {parkingAreaLoading ? (
                  <CircularProgress />
                ) : (
                  <SearchableSelect
                      placeholder="Requested Parking Area"
                      value={{ label: parkingAreaName }}
                      onChange={(e) => {
                          setParkingAreaName(e.value === "clear" ? null : e.label);
                      }}
                      options={[
                          { label: "*** Clear Filter ***", value: "clear" },
                          ...parkingAreas.map((area) => {
                              return {
                                  label: area.name,
                                  value: area.id,
                              };
                          }),
                      ]}
                  />
                )}
            </Grid>
            <Grid item xs={12} className="flex flex-grow justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Filter
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default FilterModal;