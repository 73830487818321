import { useState } from "react";

const useDisableWhileRequesting = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  const withDisable = async (callback, onError) => {
    setIsDisabled(true);
    try {
      await callback();
    } catch (error) {
      // If it being catch by somewhere then it wont show onError
      if (onError) {
        onError(error);
      } else {
        console.error(error);
      }
    } finally {
      setIsDisabled(false);
    }
  };

  return { isDisabled, withDisable };
};

export default useDisableWhileRequesting;