import { useDispatch } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { handleUpdatePayoutStatus, showPayoutModal } from './affiliateCampaignReferralSlice';
import { useParams } from 'react-router-dom'
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

const PayoutModal = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const submit = async() => {
    try {
      await withDisable(() => dispatch(handleUpdatePayoutStatus(id)));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="payout-referral-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[20%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showPayoutModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Update Selected Referral Payout Status?</p>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                disabled={isDisabled}
                className={`bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md ${
                  isDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                }`}
              >
                Paid
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default PayoutModal;