import React, { useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TablePagination, TableFooter } from "@mui/material";
import styles from './SubscriptionChargeHistory.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import { TableToolbar } from './TableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { handleGetSubscriptionChargeHistories, selectState, setSuccessMessage, setErrorMessage, setSubscriptionChargeHistoryPage, setSubscriptionChargeHistoryRowsPerPage } from './subscriptionChargeHistorySlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';
import { centToDollar } from '../../../app/utils';

export function SubscriptionChargeHistory() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { id, wallet_id } = useParams();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGetSubscriptionChargeHistories(id, wallet_id));
  }, [id, wallet_id, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangeSubscriptionChargeHistoryPage = (event, newPage) => {
    dispatch(setSubscriptionChargeHistoryPage(newPage));
    dispatch(handleGetSubscriptionChargeHistories(id, wallet_id));
  };

  const handleChangeSubscriptionChargeHistoryRowsPerPage = (event) => {
    dispatch(setSubscriptionChargeHistoryRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setSubscriptionChargeHistoryPage(0));
    dispatch(handleGetSubscriptionChargeHistories(id, wallet_id));
  };

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="p-10 text-left">
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <TableToolbar
                title={`Charge history for ${id}`}
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Invoice ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Amount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Subscription Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Error Message</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Success</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Created At</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.subscriptionChargeHistories.length > 0 ? (
                  state.subscriptionChargeHistories.map((subscription) => (
                    <TableRow key={subscription.id}>
                      <TableCell align="left" className="min-w-[120px] break-all">{subscription.id}</TableCell>
                      <TableCell align="left" className="min-w-[120px] break-all">{subscription.stripeInvoiceId}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{`${subscription.currency} ${centToDollar(subscription.finalAmount)}`}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.metadata.type}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.error?.message || 'N/A'}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.success ? 'true' : 'false'}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{moment(subscription.createdAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <p>No charge history.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={4}
                  count={state.subscriptionChargeHistoryPagination?.totalElements || 0}
                  rowsPerPage={state.subscriptionChargeHistoryRowsPerPage}
                  page={state.subscriptionChargeHistoryPage}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangeSubscriptionChargeHistoryPage}
                  onRowsPerPageChange={handleChangeSubscriptionChargeHistoryRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}