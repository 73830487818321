import { useToasts as useRTN } from 'react-toast-notifications';

const useToasts = () => {
  const { addToast } = useRTN();
  const toastSuccess = (content) => {
    addToast(content, { appearance: 'success' });
  };

  const toastError = (content) => {
    addToast(content, { appearance: 'error' });
  };

  const toastCatchError = (error) => {
    let errorMessage = 'Something went wrong!';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    toastError(errorMessage);
  };

  return {
    toastSuccess,
    toastError,
    toastCatchError,
  };
};

export default useToasts;
