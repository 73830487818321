import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  TextField,
  CircularProgress
} from "@mui/material";
import { Close } from "@mui/icons-material";

import {
  selectState,
  showViewAppointmentModal,
  setErrorMessage
} from "./terminationSlice";
import { getAppointmentById } from '../../../api/appointmentAPI';

const ViewAppointmentModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const [appointmentLoading, setAppointmentLoading] = useState(false);

  const appointmentId = state.selectedAppointmentId;
  const [appointment, setAppointment] = useState({})

  useEffect(() => {
    const fetchAppointment = async () => {
        try {
          if (appointmentId) {
            setAppointmentLoading(true);
            const response = await getAppointmentById(appointmentId);
            setAppointment(response.data);
          }
        } catch (error) {
          dispatch(setErrorMessage("Failed to fetch appointment."));
        } finally {
          setAppointmentLoading(false);
        }
    };

    fetchAppointment();
  }, [appointmentId, dispatch])

  return (
    <Modal
      open={true}
      aria-labelledby="view-appointment-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[60%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showViewAppointmentModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Appointment</p>
          {appointmentLoading ? (
            <CircularProgress />
          ) : (
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="id"
                  label="ID"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={appointment.id ?? "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="dropOffStatus"
                  label="Drop Off Status"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={appointment.delivery?.dropOffStatus ?? "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </div>
      </Paper>
    </Modal>
  );
};

export default ViewAppointmentModal;