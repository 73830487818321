import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getParkitSubscriptionChargeHistories } from '../../../api/parkitSubscriptionAPI';

export const subscriptionSlice = createSlice({
  name: 'subscriptionChargeHistory',
  initialState: {
    subscriptionChargeHistories: [],
    subscriptionChargeHistoryPagination: {},
    subscriptionChargeHistoryPage: 0,
    subscriptionChargeHistoryRowsPerPage: 10,
    selectedSubscriptionChargeHistoryId: null,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setSelectedSubscriptionChargeHistoryId: (state, action) => {
      state.selectedSubscriptionChargeHistoryId = action.payload;
    },
    setSubscriptionChargeHistoryPage: (state, action) => {
      state.subscriptionChargeHistoryPage = action.payload;
    },
    setSubscriptionChargeHistoryRowsPerPage: (state, action) => {
      state.subscriptionChargeHistoryRowsPerPage = action.payload;
    },
    setSubscriptionChargeHistoryPagination: (state, action) => {
      state.subscriptionChargeHistoryPagination = action.payload;
    },
    setSubscriptionChargeHistories: (state, action) => {
      state.subscriptionChargeHistories = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  }
});

export const { setSelectedSubscriptionChargeHistoryId, setSubscriptionChargeHistoryPage, setSubscriptionChargeHistoryRowsPerPage, setSubscriptionChargeHistoryPagination, setSubscriptionChargeHistories, setSuccessMessage, setErrorMessage } = subscriptionSlice.actions;

export const selectState = state => state.subscriptionChargeHistory;

export const handleGetSubscriptionChargeHistories = (id, wallet_id) => async (dispatch, getState) => {
  dispatch(showLoading());
  try {
    const response = await getParkitSubscriptionChargeHistories(wallet_id, id);
    const { content, ...pagination } = response.data;

    dispatch(setSubscriptionChargeHistoryPagination(pagination));
    dispatch(setSubscriptionChargeHistories(content));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
    dispatch(setSubscriptionChargeHistories([]));
  } finally {
    dispatch(hideLoading());
  }
}

export default subscriptionSlice.reducer;