import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  TextField,
  CircularProgress
} from "@mui/material";
import { Close } from "@mui/icons-material";

import {
  selectState,
  showRentingModal,
  setErrorMessage
} from "./listingSlice";
import { getRentingById } from "../../../api/rentingsAPI";
import { centToDollar } from "../../../app/utils";

const ViewRentingModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const [rentingLoading, setRentingLoading] = useState(false);

  const listing = state.selectedListing
  const [renting, setRenting] = useState({})

  useEffect(() => {
    const fetchRenting = async () => {
      setRentingLoading(true);
      try {
        const response = await getRentingById(listing.rentingId);
        setRenting(response.data);
      } catch (error) {
        dispatch(setErrorMessage("Failed to fetch renting."));
      } finally {
        setRentingLoading(false);
      }
    };

    fetchRenting();
  }, [listing, dispatch])

  return (
    <Modal
      open={true}
      aria-labelledby="view-renting-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[60%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showRentingModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Driver Information</p>
          {rentingLoading ? (
            <CircularProgress />
          ) : (
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="status"
                  label="Status"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={renting.status ?? "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="startDate"
                  label="Start Date"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={renting.startDate ?? "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="sellPrice"
                  label="Sell Price Per Month"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={renting.sellPrices?.monthly ? centToDollar(renting.sellPrices.monthly) : "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="terminationDate"
                  label="Termination Date"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={renting.termination?.customerDueDate ?? "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </div>
      </Paper>
    </Modal>
  );
};

export default ViewRentingModal;