import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@mui/material";
import styles from './Payout.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import { TableToolbar } from './TableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { handleGetPayouts, selectState, setPage, setRowsPerPage, setSelectedPayout, setSelectedId, setSuccessMessage, setErrorMessage } from './payoutSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';
import UploadPayoutModal from './UploadPayoutModal';
import { centToDollar } from '../../../app/utils';

export function Payout() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGetPayouts());
  }, [state.sort, state.sortBy, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetPayouts());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetPayouts());
  };

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="p-10 text-left">
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <TableToolbar
                numSelected={state.selectedId === null ? 0 : 1}
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Bay Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Bay Location</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Gross Rental</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Deductions</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Net Rental/Payout</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Occupied Days in the Period</TableCell>
                <TableCell align="left" className={styles.tableHeader250}>Payout Period</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.payouts.length > 0 ? (
                  state.payouts.map((payout) => (
                    <TableRow
                      key={payout.id}
                      hover
                      onClick={() => { dispatch(setSelectedId(state.selectedId === payout.id ? null : payout.id)); dispatch(setSelectedPayout(state.selectedId === payout.id ? null : payout)) }}
                      role="checkbox"
                      aria-checked={state.selectedId === payout.id ? true : false}
                      selected={state.selectedId === payout.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedId === payout.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{`${payout.customer?.user?.firstName} ${payout.customer?.user?.lastName}`}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{payout.listingBayNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{payout.listingLocation}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{centToDollar(payout.buyPrice)}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{centToDollar(payout.adjustment)}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{centToDollar(payout.disbursedAmount)}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{payout.daysOccupied}</TableCell>
                      <TableCell align="left" className={styles.tableCell250}>{`${payout.startDate} to ${payout.endDate}`}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={27} align="center">
                      <p>No payout payouts.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={4}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.uploadPayoutModalOpen && <UploadPayoutModal />}
    </div>
  );
}