import { useDispatch } from 'react-redux';
import { Modal, Paper, Grid, FormLabel } from "@mui/material";
import { Close } from "@mui/icons-material";
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

import { handleTerminateRenting, showTerminateModal } from './trackingSheetSlice';

const TerminateRentingModal = () => {
  const dispatch = useDispatch();
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const submit = async () => {
    try {
      await withDisable(() => dispatch(handleTerminateRenting()));
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="terminate-masterlist-data-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[30%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close className="float-right cursor-pointer" onClick={() => dispatch(showTerminateModal(false))} />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Terminate Renting</p>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
                <FormLabel component="legend">Are you sure you want to terminate this tracking renting?</FormLabel>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                disabled={isDisabled}
                className={`bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md ${
                  isDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                }`}
              >
                Confirm
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}

export default TerminateRentingModal;