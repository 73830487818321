import React, { Fragment } from 'react';
import { useKeycloak } from "keycloak-react-web";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import { Helmet } from "react-helmet";
import { Home } from './features/home/Home';
import { User } from './features/user/User';
import { Wallet } from './features/wallet/Wallet';
import { Dashboard as DeliverItDashboard } from './features/deliverit/dashboard/Dashboard';
import { Dashboard as ParkItDashboard } from './features/parkit/dashboard/Dashboard';
import { DeliveryRate as DeliveryRateDeliverIt } from './features/deliverit/delivery_rate/DeliveryRate';
import { OrderGroup } from './features/deliverit/order_group/OrderGroup';
import { Order } from './features/deliverit/order/Order';
import { Appointment } from './features/parkit/appointment/Appointment';
import { ParkingArea } from './features/parkit/parkingArea/ParkingArea';
import { BuildingEnquiries } from './features/parkit/buildingEnquiries/BuildingEnquiries';
import { OwnerListings } from './features/parkit/ownerListings/OwnerListings';
import { Listing } from './features/parkit/listing/Listing';
import { Renting } from './features/parkit/renting/Renting';
import { Subscription as ParkitSubscription } from './features/parkit/subscription/Subscription';
import { FailedSubscription as ParkitFailedSubscription } from './features/parkit/failedSubscription/FailedSubscription';
import { SubscriptionChargeHistory } from './features/parkit/subscriptionChargeHistory/SubscriptionChargeHistory';
import { TrackingSheet } from './features/parkit/trackingSheet/TrackingSheet';
import { Masterlist } from './features/parkit/masterlist/Masterlist';
import { Termination } from './features/parkit/termination/Termination';
import { BulkEnquiries } from './features/parkit/bulkEnquiries/BulkEnquiries';
import { ArchivedListings } from './features/parkit/archivedListings/ArchivedListings';
import { ArchivedRentings } from './features/parkit/archivedRentings/ArchivedRentings';
import { Insurance } from './features/parkit/insurance/Insurance';
import { DeliveryRate as DeliveryRateParkIt } from './features/parkit/deliveryRate/DeliveryRate';
import { AffiliateCampaign } from './features/parkit/affiliateCampaign/AffiliateCampaign';
import { AffiliateCampaignReferral } from './features/parkit/affiliateCampaignReferral/AffiliateCampaignReferral';
import { DeleteRequest } from './features/parkit/deleteRequest/DeleteRequest';
import { Voucher } from './features/voucher/Voucher';
import { Subscription } from './features/subscription/Subscription';
import { Vendor } from './features/deliverit/vendor/Vendor';
import { User as ParkitUser } from './features/parkit/user/User';
import { PayoutReports } from './features/parkit/payoutReports/PayoutReports';
import { Payout } from './features/parkit/payout/Payout';
import { closeSnackbar, selectState as selectSnackbar } from './features/snackbar/snackbarSlice';
import Nav from './components/Nav/Nav';
import PrivateRoute from './helpers/PrivateRoute';
import './App.css';

function App() {
  const { initialized } = useKeycloak();
  const snackbarState = useSelector(selectSnackbar);
  const dispatch = useDispatch();

  if (!initialized) {
    return <div>Loading...</div>
  }

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Portal</title>
      </Helmet>
      <Nav />
      <Router>
        <LoadingBar />
        <Fragment>
          <Routes>
            <Route path={"/"} element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path={"/home"} element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/users" element={<PrivateRoute><User /></PrivateRoute>} />
            <Route path="/users/:id/subscriptions" element={<PrivateRoute><Subscription /></PrivateRoute>} />
            <Route path="/wallets" element={<PrivateRoute><Wallet /></PrivateRoute>} />
            <Route path="/vouchers" element={<PrivateRoute><Voucher /></PrivateRoute>} />
            <Route path="/deliverit" element={<PrivateRoute><DeliverItDashboard /></PrivateRoute>} />
            <Route path="/deliverit/delivery-rates" element={<PrivateRoute><DeliveryRateDeliverIt /></PrivateRoute>} />
            <Route path="/deliverit/order-groups" element={<PrivateRoute><OrderGroup /></PrivateRoute>} />
            <Route exact path="/deliverit/confirmed-order-groups" element={<PrivateRoute><OrderGroup orderGroupType={"CONFIRMED"} /></PrivateRoute>} />
            <Route exact path="/deliverit/confirmed-order-groups/:id" element={<PrivateRoute><Order /></PrivateRoute>} />
            <Route exact path="/deliverit/completed-order-groups" element={<PrivateRoute><OrderGroup orderGroupType={"COMPLETED"} /></PrivateRoute>} />
            <Route exact path="/deliverit/completed-order-groups/:id" element={<PrivateRoute><Order /></PrivateRoute>} />
            <Route exact path="/deliverit/vendors" element={<PrivateRoute><Vendor /></PrivateRoute>} />
            <Route path={"/parkit"} element={<PrivateRoute><ParkItDashboard /></PrivateRoute>} />
            <Route path={"/parkit/appointment"} element={<PrivateRoute><Appointment /></PrivateRoute>} />
            <Route path={"/parkit/parking-areas"} element={<PrivateRoute><ParkingArea /></PrivateRoute>} />
            <Route path={"/parkit/building-enquiries"} element={<PrivateRoute><BuildingEnquiries /></PrivateRoute>} />
            <Route path={"/parkit/owner-listings"} element={<PrivateRoute><OwnerListings /></PrivateRoute>} />
            <Route path={"/parkit/listings"} element={<PrivateRoute><Listing /></PrivateRoute>} />
            <Route path={"/parkit/rentings"} element={<PrivateRoute><Renting /></PrivateRoute>} />
            <Route path={"/parkit/subscriptions"} element={<PrivateRoute><ParkitSubscription /></PrivateRoute>} />
            <Route path={"/parkit/failed-subscriptions"} element={<PrivateRoute><ParkitFailedSubscription /></PrivateRoute>} />
            <Route path={"/parkit/wallets/:wallet_id/subscriptions/:id"} element={<PrivateRoute><SubscriptionChargeHistory /></PrivateRoute>} />
            <Route path={"/parkit/tracking-rentings"} element={<PrivateRoute><TrackingSheet /></PrivateRoute>} />
            <Route path={"/parkit/masterlist"} element={<PrivateRoute><Masterlist /></PrivateRoute>} />
            <Route path={"/parkit/terminations"} element={<PrivateRoute><Termination /></PrivateRoute>} />
            <Route path={"/parkit/bulk-enquiries"} element={<PrivateRoute><BulkEnquiries /></PrivateRoute>} />
            <Route path={"/parkit/archived-listings"} element={<PrivateRoute><ArchivedListings /></PrivateRoute>} />
            <Route path={"/parkit/archived-rentings"} element={<PrivateRoute><ArchivedRentings /></PrivateRoute>} />
            <Route path={"/parkit/insurance"} element={<PrivateRoute><Insurance /></PrivateRoute>} />
            <Route path={"/parkit/delivery-rate"} element={<PrivateRoute><DeliveryRateParkIt /></PrivateRoute>} />
            <Route path={"/parkit/affiliate-campaigns"} element={<PrivateRoute><AffiliateCampaign /></PrivateRoute>} />
            <Route path={"/parkit/affiliate-campaigns/:id"} element={<PrivateRoute><AffiliateCampaignReferral /></PrivateRoute>} />
            <Route path={"/parkit/users"} element={<PrivateRoute><ParkitUser /></PrivateRoute>} />
            <Route path={"/parkit/delete-requests"} element={<PrivateRoute><DeleteRequest /></PrivateRoute>} />
            <Route path={"/parkit/payout-reports"} element={<PrivateRoute><PayoutReports /></PrivateRoute>} />
            <Route path={"/parkit/payout"} element={<PrivateRoute><Payout /></PrivateRoute>} />
          </Routes>
        </Fragment>
      </Router>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={6000}
        onClose={dispatch(closeSnackbar)}
        message={snackbarState.message}
      />
    </div>
  );
}

export default App;

