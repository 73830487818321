/*global google*/
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button } from "@mui/material";
import { closeEditOrderModal, handleUpdateOrder } from './orderSlice';
import { Close } from "@mui/icons-material";
import styles from './Order.module.css';
import DatePicker from "react-datepicker";
import { setMinutes, setHours } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

export function EditOrder(props) {
  const { order } = props;
  const dispatch = useDispatch();
  const [orderState, _setOrderState] = useStateWithCallbackLazy({
    form: {
      ...order
    },
    error: false,
    init: false,
    autocompleteInit: false,
    errorMessage: ""
  });
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const orderStateRef = useRef(orderState);

  const setOrderState = data => {
    orderStateRef.current = data;
    _setOrderState(data);
  };

  useEffect(() => {
    if (order && !orderState.init) {
      const {version, ...orderForm} = {...order};
      setOrderState({
        ...orderState,
        init: true,
        form: { 
          ...orderForm,
          deliverDateTime: new Date(order.deliverDateTime)
        }
      });
    }

    let dropOffAutocompleteInput = document.getElementById("dropOffAutocomplete");
    if (!orderState.autocompleteInit && dropOffAutocompleteInput !== null) {
      initializeGoogleAutocomplete("dropOffAutocomplete");
      setOrderState({
        ...orderState,
        autocompleteInit: true,
      });
    }
  });

  const onPlaceChanged = (autocomplete, elementId) => {
    var place = autocomplete.getPlace();
    if (place) {
      if (elementId === "dropOffAutocomplete") {
        _setOrderState({
          ...orderStateRef.current,
          form: {
            ...orderStateRef.current.form,
            dropOffLocation: {
              address: `${place.name}, ${place.formatted_address}`,
              coordinate: {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              }
            }
          }
        }, (state) => calculateDistance(state));
      }
    }
  }

  const initializeGoogleAutocomplete = (elementId) => {
    let autocompleteInput = document.getElementById(elementId);
    let autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: ["my"] }
      }
    );

    google.maps.event.addListener(autocomplete, "place_changed", function() {
      onPlaceChanged(autocomplete, elementId);
    });
  }

  const calculateDistance = (orderState) => {
    orderStateRef.current = orderState;
    if (props.orderGroup.pickUpLocation.address !== "" && orderState.form.dropOffLocation.address !== "") {
      let service = new window.google.maps.DistanceMatrixService();
      let origin = {lat: props.orderGroup.pickUpLocation.coordinate.latitude, lng: props.orderGroup.pickUpLocation.coordinate.longitude}
      let destination = {lat: orderState.form.dropOffLocation.coordinate.latitude, lng: orderState.form.dropOffLocation.coordinate.longitude};
  
      service.getDistanceMatrix({
        origins: [origin],
        destinations: [destination],
        travelMode: window.google.maps.TravelMode.DRIVING,
      }, (response, status) => {
        if (status !== "OK") {
          alert("Error calculate distance to delivery address");
          setOrderState({...orderState, form: { ...orderState.form, dropOffLocation: {address: "", coordinate: {latitude: "", longitude: ""}}}});
        } else {
          const results = response.rows[0].elements;
          if (results[0].status === 'OK') {
            setOrderState({...orderState, form: { ...orderState.form, deliveryRange: results[0].distance.value}});
          } else {
            alert("Error calculate distance to delivery address");
            setOrderState({...orderState, form: { ...orderState.form, dropOffLocation: {address: "", coordinate: {latitude: "", longitude: ""}}}});
          }
        }
      });
    }
  }

  const onFormValueChange = (data) => {
    setOrderState({
      ...orderState,
      form: {
        ...orderState.form,
        [data.name]: data.value
      }
    })
  }

  const submit = async () => {
    let error = false;
    if (orderState.form.deliveryRange === 0) {
      error = true;
      setOrderState({...orderState, error: true, errorMessage: "Pickup and dropoff location are the same"});
      return;
    }

    for (var key in orderState.form) {
      if (key !== "recipientEmail") {
        if (orderState.form[key] === null || orderState.form[key] === "") {
          error = true;
          setOrderState({...orderState, error: true, errorMessage: "All fields are required"});
          return;
        }
      }
    }

    if (!error) {
      // Convert Datetime to UTC
      let orderPayload = {
        ...orderState.form,
        deliverDateTime: orderState.form.deliverDateTime.toISOString()
      }
      await withDisable(
        () => dispatch(handleUpdateOrder(props.orderGroup.id, orderPayload, order.id)),
        (err) => {
          setOrderState({...orderState, error: true, errorMessage: err});
        }
      );
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-modal"
      className={styles.modal}
    >
      <Paper className={`${styles.modalContainer} scrollbar-hidden`}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeEditOrderModal())}/>
          <h3 className={styles.subtitle}>What do you want us to deliver? <em style={{fontSize: 12}}>(All fields are required)</em></h3>
          <Fade in={orderState.error}>
            <p className={styles.errorMessage}>{orderState.errorMessage}</p>
          </Fade>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <p>Recipient</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="recipientName"
                  name="recipientName"
                  value={orderState.form.recipientName}
                  onChange={e => onFormValueChange({name: "recipientName", value: e.target.value})}
                  placeholder="Recipient Name"
                  className={styles.textInput}
                />
              </Paper>
              <Paper className={styles.textInputContainer}>
                <input
                  id="recipientPhone"
                  name="recipientPhone"
                  type="tel"
                  value={orderState.form.recipientPhone}
                  onChange={e => onFormValueChange({name: "recipientPhone", value: e.target.value})}
                  placeholder="Phone Number"
                  className={styles.textInput}
                />
              </Paper>
              <Paper className={styles.textInputContainer}>
                <input
                  id="recipientEmail"
                  name="recipientEmail"
                  type="email"
                  value={orderState.form.recipientEmail}
                  onChange={e => onFormValueChange({name: "recipientEmail", value: e.target.value})}
                  placeholder="Recipient Email (optional)"
                  className={styles.textInput}
                />
              </Paper>
              <Paper className={styles.textInputContainer}>
                <DatePicker
                  selected={orderState.form.deliverDateTime}
                  onChange={date => {
                    if (date.getHours() === 0) {
                      date.setHours(9);
                    }
                    onFormValueChange({name: "deliverDateTime", value: date})
                  }}
                  showTimeSelect
                  dateFormat="Pp"
                  minDate={new Date()}
                  minTime={setHours(setMinutes(new Date(), 0), 9)}
                  maxTime={setHours(setMinutes(new Date(), 0), 18)}
                  timeIntervals={15}
                  className={styles.textInput}
                  placeholderText={"Delivery Date Time"}
                />
              </Paper>
              <p>Product</p>
              <Paper className={styles.textInputContainer}>
                <textarea
                  id="product"
                  name="product"
                  value={orderState.form.product}
                  onChange={e => onFormValueChange({name: "product", value: e.target.value})}
                  placeholder="Product"
                  className={styles.textInput}
                />
              </Paper>
              <p>Drop-off address</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="dropOffAutocomplete"
                  name="dropOffAddress"
                  value={orderState.form.dropOffLocation.address}
                  onChange={e => setOrderState({
                    ...orderState,
                    form: {
                      ...orderState.form,
                      dropOffLocation: {
                        ...orderState.form.dropOffLocation,
                        address: e.target.value
                      }
                    }
                  })}
                  placeholder="Delivery Address"
                  className={styles.textInput}
                />
              </Paper>
              <div className={styles.submitButtonContainer}>
                <Button disabled={isDisabled} onClick={() => submit()} className={styles.gradientButton}>Submit</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
