import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@mui/material";
import styles from './AffiliateCampaignReferral.module.css';
import { useParams } from 'react-router-dom'
import { StickyTableHead } from '../../../helpers/GeneralComponent';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import FilterModal from './FilterModal';
import MonthSelectionModal from './MonthSelectionModal';
import PayoutModal from './PayoutModal';
import { TableToolbar } from './TableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { handleGetAffiliateCampaignReferrals, selectState, setPage, setRowsPerPage, setSelectedReferrals, setSelectedIds, setSuccessMessage, setErrorMessage } from './affiliateCampaignReferralSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';

export function AffiliateCampaignReferral() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();
  const { id } = useParams();

  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetAffiliateCampaignReferrals(id));
    }
  });

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetAffiliateCampaignReferrals(id));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetAffiliateCampaignReferrals(id));
  };

  const handleRowClick = (event, referral) => {
    const selectedIndex = state.selectedIds.indexOf(referral.id);
    let newSelectedIds = [...state.selectedIds];
    let newSelectedReferrals = [...state.selectedReferrals];

    if (selectedIndex === -1) {
      newSelectedIds.push(referral.id);
      newSelectedReferrals.push(referral);
    } else {
      newSelectedIds.splice(selectedIndex, 1);
      newSelectedReferrals.splice(selectedIndex, 1);
    }

    dispatch(setSelectedIds(newSelectedIds));
    dispatch(setSelectedReferrals(newSelectedReferrals));
  };

  const isSelected = (id) => state.selectedIds.indexOf(id) !== -1;

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="p-10 text-left">
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <TableToolbar
                numSelected={state.selectedIds.length}
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Contact Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Renting ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Enquired Date</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Admin Fee Payment Date</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Deposit Payment Date</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Renting Subscription Start Date</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Renting Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Location</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Monthly Sell Price</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Referrer Payment Amount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Referrer Payout Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Last Payout Date (Referrer)</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.affiliateCampaignReferrals.length > 0 ? (
                  state.affiliateCampaignReferrals.map((referral) => (
                    <TableRow
                      key={referral.id}
                      hover
                      onClick={(event) => handleRowClick(event, referral)}
                      role="checkbox"
                      aria-checked={isSelected(referral.id)}
                      selected={isSelected(referral.id)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isSelected(referral.id)}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.referralName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.referralEmail}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.referralContact}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.rentingId}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.enquiredDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.adminFeePaymentDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.depositPaymentDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.subscriptionStartDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.status}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.location}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.monthlySellPrice}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.referrerPayableAmount}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.referrerPayoutStatus}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{referral.lastPayoutDate}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={27} align="center">
                      <p>No affiliate campaign referrals.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={4}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.filterModalOpen && <FilterModal />}
      {state.monthSelectionModalOpen && <MonthSelectionModal />}
      {state.payoutModalOpen && <PayoutModal />}
    </div>
  );
}