import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  TextField,
  CircularProgress
} from "@mui/material";
import { Close } from "@mui/icons-material";

import {
  selectState,
  showViewPromoCodeModal,
  setErrorMessage
} from "./affiliateCampaignSlice";
import * as VoucherAPI from "../../voucher/voucherAPI";

const ViewPromoCodeModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const [promoCodeLoading, setPromoCodeLoading] = useState(false);

  const affiliateCampaign = state.selectedCampaign
  const [promoCode, setPromoCode] = useState({})

  useEffect(() => {
    const fetchPromoCode = async () => {
      setPromoCodeLoading(true);
      try {
        const response = await VoucherAPI.getVoucherByCode(affiliateCampaign.referralPromoCode);
        setPromoCode(response);
      } catch (error) {
        dispatch(setErrorMessage("Failed to fetch promo code."));
      } finally {
        setPromoCodeLoading(false);
      }
    };

    fetchPromoCode();
  }, [affiliateCampaign, dispatch])

  return (
    <Modal
      open={true}
      aria-labelledby="view-promo-code-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[60%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showViewPromoCodeModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Promo Code</p>
          {promoCodeLoading ? (
            <CircularProgress />
          ) : (
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="code"
                  label="Code"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={promoCode.code ?? "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="title"
                  label="Title"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={promoCode.title ?? "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="redeemLimit"
                  label="Redeem Limit"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={promoCode.redeemLimit ?? "No limit"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="perUserRedeemLimit"
                  label="Per User Redeem Limit"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={promoCode.perUserRedeemLimit ?? "No limit per user"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </div>
      </Paper>
    </Modal>
  );
};

export default ViewPromoCodeModal;