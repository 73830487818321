import React from 'react';
import { useDispatch } from 'react-redux';
import { closeDeleteOrderModal, handleDeleteOrder } from './orderSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

export function ConfirmDelete(props) {
    const { order } = props;
    const dispatch = useDispatch();
    const { isDisabled, withDisable } = useDisableWhileRequesting();

    const submit = async () => {
        await withDisable(() => dispatch(handleDeleteOrder(props.orderGroup.id, order.id)));
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeDeleteOrderModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Delete Order</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this order?
                </DialogContentText>  
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeDeleteOrderModal())} color="primary">
                    Cancel
                </Button>
                <Button disabled={isDisabled} onClick={() => submit()} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}