import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Paper, Grid, FormLabel, Typography, Checkbox, Button, FormControlLabel } from "@mui/material";
import { Close } from "@mui/icons-material";
import { centToDollar } from '../../../app/utils';
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

import {
  handleActivateRenting,
  selectState,
  showActivateModal,
} from "./trackingSheetSlice";

const ActivateRentingModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const renting = state.selectedRenting;
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const [isVerified, setIsVerified] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const handleCheckboxChange = (event) => {
    setIsVerified(event.target.checked);
  };

  useEffect(() => {
    const isStartDateValid = new Date(renting.startDate) >= new Date(renting.subscriptionStartDate ?? renting.startDate);
    setIsValid(isStartDateValid);
  }, [renting.startDate, renting.subscriptionStartDate]);

  const submit = async () => {
    if (isVerified) {
      try {
        const payload = {
          startDate: state.activateStartDate,
        };
        await withDisable(() => dispatch(handleActivateRenting(payload)));
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="activate-renting-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showActivateModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
            <p className="mb-[10px] text-[20px] font-medium">Activate Renting</p>
            <FormLabel style={{ marginBottom: '20px' }} component="legend">Are you sure you want to activate this renting?</FormLabel>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>
                      <strong>Start Date:</strong> {renting.startDate}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                      <strong>Subscription Start Date:</strong> {renting.subscriptionStartDate ?? renting.startDate}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                      <strong>Address:</strong> {renting.listing?.parkingBay?.bayNumber} - {renting.listing?.parkingBay?.address}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                      <strong>Sell Price Per Month:</strong> {centToDollar(renting.sellPrices?.monthly)}
                  </Typography>
                </Grid>

                {!isValid ? (
                  <Typography color="error" style={{ marginTop: '10px' }}>
                      Start Date cannot be earlier than Subscription Start Date.
                  </Typography>
                ) : (
                  <Grid item xs={12}>
                      <FormControlLabel
                          control={
                              <Checkbox
                                  checked={isVerified}
                                  onChange={handleCheckboxChange}
                                  color="primary"
                              />
                          }
                          label="I hereby acknowledge to activate this renting."
                      />
                  </Grid>
                )}

                <Grid item xs={12} className="flex justify-center">
                    <Button 
                        variant="contained" 
                        onClick={submit}
                        disabled={!isVerified || !isValid || isDisabled}
                        sx={{
                          backgroundColor: '#DE4D65',
                          color: '#fff',
                          '&:hover': {
                              backgroundColor: '#b93a4e', // Slightly darker shade on hover
                          },
                          marginTop: '20px'
                        }}
                    >
                        Activate
                    </Button>
                </Grid>
            </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default ActivateRentingModal;
