import React from 'react';
import { useDispatch } from 'react-redux';
import { closeDeleteDeliveryRateModal, handleGetDeliveryRateProfiles } from './deliveryRateSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { deleteDeliveryRateProfile } from './deliveryRateAPI';
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

export function ConfirmDelete(props) {
    const { deliveryRate } = props;
    const dispatch = useDispatch();
    const { isDisabled, withDisable } = useDisableWhileRequesting();

    const submit = async (props) => {
        try {
            await withDisable(() => deleteDeliveryRateProfile(deliveryRate.id));
    
            dispatch(closeDeleteDeliveryRateModal());
            dispatch(handleGetDeliveryRateProfiles());
        } catch (error) {
            alert(error.message);
        }
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeDeleteDeliveryRateModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Delete Delivery Rate</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this delivery rate?
                </DialogContentText>  
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeDeleteDeliveryRateModal())} color="primary">
                    Cancel
                </Button>
                <Button disabled={isDisabled} onClick={() => submit(props)} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}