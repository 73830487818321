import React from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { closeConfirmPauseChargeModal, handlePauseCharge } from './subscriptionSlice';
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

export function ConfirmPauseChargeModal(props) {
    const dispatch = useDispatch();
    const { selectedId } = props;
    const { isDisabled, withDisable } = useDisableWhileRequesting();

    const submit = async () => {
        await withDisable(() => dispatch(handlePauseCharge()));
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeConfirmPauseChargeModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Pause charge</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Are you sure you want to pause charge for this subscription (${selectedId})?`}
                </DialogContentText>  
            </DialogContent>
            <DialogActions>
                <button 
                    className="flex bg-slate-200 w-fit justify-center mx-[5px] px-[20px] py-[8px] text-black text-[14px] font-medium rounded-[5px]" 
                    onClick={() => dispatch(closeConfirmPauseChargeModal())}
                >
                    Cancel
                </button>
                <button
                    onClick={submit}
                    disabled={isDisabled}
                    className={`flex bg-[#DE4D65] w-fit justify-center mx-[5px] px-[20px] py-[8px] text-white text-[14px] font-medium rounded-[5px] ${
                        isDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                    }`}
                >
                    Submit
                </button>
            </DialogActions>
        </Dialog>
        </div>
    );
}