import axiosApiInstance from "../app/axios";
import { PARKIT_API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const getUserVehicles = async (userName) =>
  axiosApiInstance.get(`${PARKIT_API_BASE_URL}/parkit/v2/vehicles?userName=${userName}`, {
    headers: { Authorization: `Bearer ${getJwtToken()}` },
  });

export const createUserVehicle = async (userName, data) =>
  axiosApiInstance.post(`${PARKIT_API_BASE_URL}/parkit/v2/vehicles?userName=${userName}`, data, {
    headers: { Authorization: `Bearer ${getJwtToken()}` },
  });

export const updateVehicle = async (id, data) =>
  axiosApiInstance.put(`${PARKIT_API_BASE_URL}/parkit/v2/vehicles/${id}`, data, {
    headers: { Authorization: `Bearer ${getJwtToken()}` },
  });
