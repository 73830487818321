import React from 'react';
import { useDispatch } from 'react-redux';
import { handleActivateVoucher, closeActivateVoucherModal } from './voucherSlice';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import useDisableWhileRequesting from "./../../hooks/useDisableWhileRequesting";

export function ActivateVoucher(props) {
    const { voucher } = props;
    const dispatch = useDispatch();
    const { isDisabled, withDisable } = useDisableWhileRequesting();

    const submit = async () => {
        let voucherPayload = {...voucher};
        if (voucher.whiteList && voucher.whiteList.length > 0) {
            voucherPayload = {
                ...voucher,
                whiteList: [
                    ...voucher.whiteList.map(u => u.id),
                ]
            }
        }

        await withDisable(() => dispatch(handleActivateVoucher(voucher.id, voucherPayload)));
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeActivateVoucherModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Activate Voucher</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to activate this voucher?
                </DialogContentText>  
                <br/>
            </DialogContent>
            <DialogActions>
                <button
                    onClick={submit}
                    disabled={isDisabled}
                    className={`flex bg-[#DE4D65] w-fit justify-center mx-[5px] px-[20px] py-[8px] text-white text-[14px] font-medium rounded-[5px] ${
                        isDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                    }`}
                >
                    Submit
                </button>
                <button 
                    className="flex bg-[#E86C6D] w-fit justify-center mx-[5px] px-[20px] py-[8px] text-white text-[14px] font-medium rounded-[5px]" 
                    onClick={() => dispatch(closeActivateVoucherModal())}
                >
                    Cancel
                </button>
            </DialogActions>
        </Dialog>
        </div>
    );
}