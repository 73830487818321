import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Paper, Grid, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

import { handleUpdateListing, selectState, showEditListingModal } from './masterlistSlice';
import { centToDollar, dollarToCent } from '../../../app/utils';

const EditListingModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const renting = state.selectedRenting;
  const [bayNumber, setBayNumber] = useState(renting.listing.parkingBay?.bayNumber);
  const [monthlyPrice, setMonthlyPrice] = useState(centToDollar(renting.listing.buyPrices?.monthly));

  const submit = async () => {
    try {
      const payload = {
        buyPrices: {
            ...renting.listing.buyPrices,
            monthly: dollarToCent(monthlyPrice)
        },
        parkingBay: {
          ...renting.listing.parkingBay,
          bayNumber
        },
      }
      await withDisable(() => dispatch(handleUpdateListing(payload)));
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="edit-masterlist-data-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close className="float-right cursor-pointer" onClick={() => dispatch(showEditListingModal(false))} />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Edit Masterlist Listing Data</p>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={5}>
                <TextField
                    id="bayNumber"
                    label="Bay Number"
                    onChange={(e) => setBayNumber(e.target.value)}
                    value={bayNumber}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                id="buyPrice"
                label="Buy Price Per Month"
                onChange={(e) => setMonthlyPrice(e.target.value)}
                value={monthlyPrice}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                disabled={isDisabled}
                className={`bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md ${
                  isDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                }`}
              >
                Update
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}

export default EditListingModal;