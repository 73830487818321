import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  TextField,
  CircularProgress
} from "@mui/material";
import { Close } from "@mui/icons-material";

import {
  selectState,
  showViewOrderGroupModal,
  setErrorMessage
} from "./appointmentSlice";
import * as OrderGroupAPI from "../../deliverit/order_group/orderGroupAPI";

const ViewOrderGroupModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const [orderGroupLoading, setOrderGroupLoading] = useState(false);

  const appointment = state.selectedAppointment
  const [orderGroup, setOrderGroup] = useState({})

  useEffect(() => {
    const fetchOrderGroup = async () => {
      setOrderGroupLoading(true);
      try {
        const response = await OrderGroupAPI.getOrderGroupById(appointment.orderGroupId);
        setOrderGroup(response.data);
      } catch (error) {
        dispatch(setErrorMessage("Failed to fetch order group."));
      } finally {
        setOrderGroupLoading(false);
      }
    };

    fetchOrderGroup();
  }, [appointment, dispatch])

  return (
    <Modal
      open={true}
      aria-labelledby="view-order-group-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[60%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showViewOrderGroupModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Order Group</p>
          {orderGroupLoading ? (
            <CircularProgress />
          ) : (
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="id"
                  label="ID"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.id ?? "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="pickUpJobId"
                  label="Pick Up Job Id"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.pickUpJobId ?? "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="dropOffDeliveryJobId"
                  label="Drop Off Job Id"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.orders?.[0]?.deliveryJobId || "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="pickUpTrackingLink"
                  label="Pick Up Tracking Link"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.trackingLink ?? "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="dropOffTrackingLink"
                  label="Drop Off Tracking Link"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.orders?.[0]?.trackingLink || "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="pickUpState"
                  label="Pick Up State"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.status ?? "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="dropOffState"
                  label="Drop Off State"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.orders?.[0]?.status || "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="pickUpSuccess"
                  label="Pick Up Success"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.hadPickedUp ? "SUCCESS" : "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="dropOffSuccess"
                  label="Drop Off Success"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.orders?.[0]?.hadDelivered ? "SUCCESS" : "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="pickUpCompletionTime"
                  label="Pick Up Completion Time"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.completionTime ?? "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="dropOffCompletionTime"
                  label="Drop Off Completion Time"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.orders?.[0]?.completionTime || "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="proofOfDelivery"
                  label="Proof Of Delivery"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.orders?.[0]?.proofOfDelivery || "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="dropOffRating"
                  label="Drop Off Rating"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={orderGroup.orders?.[0]?.ratings || "N/A"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className="flex justify-center">
                <button
                  onClick={() => window.open("/deliverit/order-groups", "_blank")}
                  className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
                >
                  Go to DeliverIt
                </button>
              </Grid>
            </Grid>
          )}
        </div>
      </Paper>
    </Modal>
  );
};

export default ViewOrderGroupModal;