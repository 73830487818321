import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as Api from "./subscriptionAPI";
import * as WalletApi from "../../api/walletAPI";
import * as UserApi from "../user/userAPI";
import { SubscriptionChargeStatus } from '../../app/constants';

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    user: null,
    wallet: null,
    failedSubscriptions: [],
    failedSubscriptionPagination: {},
    failedSubscriptionPage: 0,
    failedSubscriptionRowsPerPage: 10,
    selectedFailedSubscriptionId: null,
    confirmRetryPaymentModalOpen: false,
    selectedId: null,
    selectedWalletId: null,
    confirmPauseChargeModalOpen: false,
    subscriptions: [],
    status: 'idle',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    openConfirmRetryPaymentModal: (state) => {
      state.confirmRetryPaymentModalOpen = true;
    },
    closeConfirmRetryPaymentModal: (state) => {
      state.confirmRetryPaymentModalOpen = false;
    },
    setSelectedFailedSubscriptionId: (state, action) => {
      state.selectedFailedSubscriptionId = action.payload;
    },
    setFailedSubscriptionPage: (state, action) => {
      state.failedSubscriptionPage = action.payload;
    },
    setFailedSubscriptionRowsPerPage: (state, action) => {
      state.failedSubscriptionRowsPerPage = action.payload;
    },
    setFailedSubscriptionPagination: (state, action) => {
      state.failedSubscriptionPagination = action.payload;
    },
    setFailedSubscriptions: (state, action) => {
      state.failedSubscriptions = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedWalletId: (state, action) => {
      state.selectedWalletId = action.payload;
    },
    openConfirmPauseChargeModal: (state) => {
      state.confirmPauseChargeModalOpen = true;
    },
    closeConfirmPauseChargeModal: (state) => {
      state.confirmPauseChargeModalOpen = false;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
      state.status = 'success'
    },
    setWallet: (state, action) => {
      state.wallet = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { setSelectedWalletId, openConfirmPauseChargeModal, closeConfirmPauseChargeModal, setSelectedId, openConfirmRetryPaymentModal, closeConfirmRetryPaymentModal, setSelectedFailedSubscriptionId, setFailedSubscriptionPage, setFailedSubscriptionRowsPerPage, setFailedSubscriptionPagination, setFailedSubscriptions, setPage, setRowsPerPage, setPagination, setSubscriptions, setWallet, setUser, setSuccessMessage, setErrorMessage } = subscriptionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.voucher.value)`
export const selectState = state => state.subscription;

export const handleGetFailedSubscriptions = (userId) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const user = await UserApi.getUser(userId);
    const response = await Api.getParkitSubscriptionsByUsername(user.userName, state.subscription.failedSubscriptionPage, state.subscription.failedSubscriptionRowsPerPage, `${SubscriptionChargeStatus.FAILED},${SubscriptionChargeStatus.RETRYING}`);
    const { content, ...pagination } = response.data;

    dispatch(setUser(user));
    dispatch(setFailedSubscriptionPagination(pagination));
    dispatch(setFailedSubscriptions(content));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
    dispatch(setFailedSubscriptions([]));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleRetryPayment = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    await Api.manualChargeParkitSubscription(state.subscription.selectedFailedSubscriptionId);
    dispatch(handleGetFailedSubscriptions(state.subscription.user.id));
    dispatch(closeConfirmRetryPaymentModal());
    dispatch(setSuccessMessage('Subscription payment retry requested successfully'));
  } catch (error) {
    console.log(error);
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleGetSubscriptions = (userId) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const user = await UserApi.getUser(userId);
    const wallet = await WalletApi.getUserWallet(userId);
    const response = await Api.getParkitSubscriptionsByUsername(user.userName, state.subscription.page, state.subscription.rowsPerPage, `${SubscriptionChargeStatus.PENDING},${SubscriptionChargeStatus.PAID},${SubscriptionChargeStatus.PAUSE_CHARGE}`);
    const { content, ...pagination } = response.data;
    
    dispatch(setUser(user));
    dispatch(setWallet(wallet));
    dispatch(setPagination(pagination));
    dispatch(setSubscriptions(content));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
    dispatch(setSubscriptions([]));
  } finally {
    dispatch(hideLoading());
  }
};

export const handlePauseCharge = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    await Api.pauseChargeSubscription(state.subscription.selectedId, state.subscription.selectedWalletId);
    dispatch(handleGetSubscriptions(state.subscription.user.id));
    dispatch(closeConfirmPauseChargeModal());
    dispatch(setSuccessMessage('Subscription pause charge requested successfully'));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export default subscriptionSlice.reducer;
