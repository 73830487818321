import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getOrderGroups, getOrderGroupsByUsers, getReportByOrderGroupStatus, updateOrderGroup, deleteOrderGroup } from './orderGroupAPI';

export const orderGroupSlice = createSlice({
  name: 'orderGroup',
  initialState: {
    orderGroups: [],
    status: 'idle',
    editOrderGroupPaymentStatusModalOpen: false,
    editOrderGroupModalOpen: false,
    deleteOrderGroupModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: localStorage.getItem("rowsPerPage") ? parseInt(localStorage.getItem("rowsPerPage")) : 10,
    selectedIds: []
  },
  reducers: {
    openEditOrderGroupModal: (state) => {
      state.editOrderGroupModalOpen = true;
    },
    closeEditOrderGroupModal: (state) => {
      state.editOrderGroupModalOpen = false;
    },
    openEditOrderGroupPaymentStatusModal: (state) => {
      state.editOrderGroupPaymentStatusModalOpen = true;
    },
    closeEditOrderGroupPaymentStatusModal: (state) => {
      state.editOrderGroupPaymentStatusModalOpen = false;
    },
    openDeleteOrderGroupModal: (state) => {
      state.deleteOrderGroupModalOpen = true;
    },
    closeDeleteOrderGroupModal: (state) => {
      state.deleteOrderGroupModalOpen = false;
      state.selectedIds = [];
    },
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setOrderGroups: (state, action) => {
      state.orderGroups = action.payload.map(o => o.pickUpDateTime ? {...o, pickUpDateTime: new Date(o.pickUpDateTime).toString()} : o);
      state.status = 'success'
    },
  },
});

export const { openEditOrderGroupModal, closeEditOrderGroupModal, openEditOrderGroupPaymentStatusModal, closeEditOrderGroupPaymentStatusModal, openDeleteOrderGroupModal, closeDeleteOrderGroupModal, setSelectedIds, setPage, setRowsPerPage, setPagination, setOrderGroups } = orderGroupSlice.actions;

export const handleGetOrderGroups = (orderGroupType) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  let status = orderGroupType === 'COMPLETED' ? 'SUCCESS,PARTIAL_SUCCESS' : 'CONFIRMED,ASSIGNED,IN_TRANSIT'
  try {
    const response = await getOrderGroups(state.orderGroup.page, state.orderGroup.rowsPerPage, status);
    const { content, ...pagination } = response.data;
    let orderGroups = content;

    dispatch(setPagination(pagination));
    switch(orderGroupType) {
      case 'OPEN':
        dispatch(setOrderGroups(orderGroups.filter(orderGroup => orderGroup.status === "OPEN")));
        break;
      case 'COMPLETED':
        dispatch(setOrderGroups(orderGroups.filter(orderGroup => orderGroup.status === "SUCCESS" || orderGroup.status === "PARTIAL_SUCCESS")));
        break;
      default:
        dispatch(setOrderGroups(orderGroups.filter(orderGroup => orderGroup.status !== "OPEN" && orderGroup.status !== "SUCCESS" && orderGroup.status !== "PARTIAL_SUCCESS")));
        break;
    }
  } catch (error) {
    console.log(error.message);
    alert(error.message)
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetOrderGroupsByFilter = (vendorFirstName, vendorCompany, paids, status) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  let statusString = status.join(',')
  try {
    let filterQuery = ""
    if (vendorFirstName !== undefined) {
      filterQuery = `${filterQuery}&vendorFirstName=${vendorFirstName}` 
    }
    if (vendorCompany !== undefined) {
      filterQuery = `${filterQuery}&vendorCompany=${vendorCompany}` 
    }
    if (paids !== undefined) {
      filterQuery = `${filterQuery}&paids=${paids}` 
    }
    if (status !== undefined) {
      filterQuery = `${filterQuery}&status=${statusString}` 
    }
    const response = await getOrderGroupsByUsers(state.orderGroup.page, state.orderGroup.rowsPerPage, filterQuery);
    const { content, ...pagination } = response.data;
    let orderGroups = content;

    dispatch(setPagination(pagination));
    dispatch(setOrderGroups(orderGroups));
  } catch (error) {
    console.log(error.message);
    alert(error.message)
  } finally {
    dispatch(hideLoading());
  }
};

export const handleDownloadOrderGroups = (orderGroupType) => async (dispatch, getState) => {
  dispatch(showLoading());

  let status = orderGroupType === 'COMPLETED' ? 'SUCCESS,PARTIAL_SUCCESS' : 'CONFIRMED,ASSIGNED,IN_TRANSIT'
  try {
    const response = await getReportByOrderGroupStatus(status);
    
    let report = await response.data;
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([report]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${orderGroupType}-ordergroup-report.csv`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};


export const handleDeleteOrderGroup = (id, orderGroupType) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const response = await deleteOrderGroup(id);
    if(response.error) throw response.error;
    dispatch(closeDeleteOrderGroupModal());
    dispatch(handleGetOrderGroups(orderGroupType));
  } catch (error) {
    console.log(error.message);
    alert(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateOrderGroup = (payload, id, orderGroupType) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const response = await updateOrderGroup(payload, id);
    if(response.error) throw response.error;
    dispatch(closeEditOrderGroupModal());
    dispatch(handleGetOrderGroups(orderGroupType));
  } finally {
    dispatch(hideLoading());
  }
};

export const selectState = state => state.orderGroup;

export default orderGroupSlice.reducer;
