import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, Paper, Checkbox, TablePagination, TableFooter, MenuItem, Select, Fab, InputBase, IconButton, debounce } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Moment from 'moment';
import styles from './ParkingArea.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';
import SearchIcon from '@mui/icons-material/Search';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import AddParkingAreaModal from './AddParkingAreaModal';
import EditParkingAreaModal from './EditParkingAreaModal';
import DeleteParkingAreaModal from './DeleteParkingAreaModal';
import { TableToolbar } from './TableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { handleGetParkingAreas, selectState, setSearchValue, setSearchField, setPage, setRowsPerPage, setSort, setSortBy, setSelectedArea, setSelectedId, showAddModal } from './parkingAreaSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import { setSuccessMessage, setErrorMessage } from './parkingAreaSlice';
import useToasts from '../../../hooks/useToasts';

import parkingAreaPlaceholder from '../../../assets/icons/parking-area-placeholder.png';
import { centToDollar } from '../../../app/utils';

export function ParkingArea() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGetParkingAreas());
  }, [state.sort, state.sortBy, state.searchField, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetParkingAreas());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetParkingAreas());
  };

  const handleRequestSort = (property) => {
    const isDescending = state.sortBy === property && state.sort === 'desc';
    dispatch(setSort(isDescending ? 'asc' : 'desc'));
    dispatch(setSortBy(property));
  };

  const onSearchValueChange = (e) => {
    dispatch(setPage(0));
    dispatch(setSearchValue(e.target.value));
    dispatch(handleGetParkingAreas());
  }

  const debouncedOnSearchValueChange = debounce(onSearchValueChange, 1000);

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="fixed bottom-6 right-6" onClick={() => dispatch(showAddModal(true))}>
        <Fab color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      </div>
      <div className="p-10 text-left">
        <Paper
          component="form"
          sx={{ p: '2px 4px', mb: '10px', display: 'flex', alignItems: 'center' }}
        >
          <Select
            value={state.searchField}
            onChange={e => dispatch(setSearchField(e.target.value))}
            sx={{ mr: 1, minWidth: 120 }}
          >
            <MenuItem value="address">Address</MenuItem>
            <MenuItem value="name">Name</MenuItem>
          </Select>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search', 'className': 'focus:shadow-none focus:ring-0' }}
            onChange={debouncedOnSearchValueChange}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <TableToolbar numSelected={state.selectedId === null ? 0 : 1} />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader110}>Image</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'name'}
                    direction={state.sortBy === 'name' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Slug Name</TableCell>
                <TableCell align="left" className={styles.tableHeader250}>Description</TableCell>
                <TableCell align="left" className={styles.tableHeader250}>Address</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'additionalInformation.totalListing'}
                    direction={state.sortBy === 'additionalInformation.totalListing' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('additionalInformation.totalListing')}
                  >
                    Total Bays
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'additionalInformation.totalVacant'}
                    direction={state.sortBy === 'additionalInformation.totalVacant' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('additionalInformation.totalVacant')}
                  >
                    Total Available Bays
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'additionalInformation.totalTerminating'}
                    direction={state.sortBy === 'additionalInformation.totalTerminating' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('additionalInformation.totalTerminating')}
                  >
                    Total Terminating Bays
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Total Inactive Bays</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Currency</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'estimatedPrice.avg'}
                    direction={state.sortBy === 'estimatedPrice.avg' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('estimatedPrice.avg')}
                  >
                    Estimated Average Price
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'overridePrice.avg'}
                    direction={state.sortBy === 'overridePrice.avg' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('overridePrice.avg')}
                  >
                    Override Average Price
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader250}>Remarks</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Active</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Hot</TableCell>
                <TableCell align="left" className={styles.tableHeader}>EV Charging</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Stripe Product ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Annual Discount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Half-Yearly Discount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Quarterly Discount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Monthly Discount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Bi-Weekly Discount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Weekly Discount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Management Contact</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Latitude</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Longitude</TableCell>
                <TableCell align="left" className={styles.tableHeader110}>
                  <TableSortLabel
                    active={state.sortBy === 'createdAt'}
                    direction={state.sortBy === 'createdAt' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('createdAt')}
                  >
                    Created At
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.parkingAreas.length > 0 ? (
                  state.parkingAreas.map((parkingArea) => (
                    <TableRow
                      key={parkingArea.id}
                      hover
                      onClick={() => { dispatch(setSelectedId(state.selectedId === parkingArea.id ? null : parkingArea.id)); dispatch(setSelectedArea(state.selectedId === parkingArea.id ? null : parkingArea)) }}
                      role="checkbox"
                      aria-checked={state.selectedId === parkingArea.id ? true : false}
                      selected={state.selectedId === parkingArea.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedId === parkingArea.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell110}>
                        <img className="w-[80px] h-[80px] object-cover" src={parkingArea.additionalInformation?.images?.length !== 0 ? parkingArea.additionalInformation?.images[0].url : parkingAreaPlaceholder} alt={parkingArea.name}/>
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.name}</TableCell>
                      <TableCell align="left" className={styles.tableCell110}>{parkingArea.additionalInformation?.slugName}</TableCell>
                      <TableCell align="left" className={styles.tableCell110}>{parkingArea.additionalInformation?.description}</TableCell>
                      <TableCell align="left" className={styles.tableCell250}>{parkingArea.address}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.additionalInformation?.totalListing}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.additionalInformation?.totalVacant}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.additionalInformation?.totalTerminating}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.additionalInformation?.totalInactive}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.currency}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{centToDollar(parkingArea.estimatedPrice?.avg)}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{centToDollar(parkingArea.overridePrice?.avg)}</TableCell>
                      <TableCell align="left" className={styles.tableCell250}>{parkingArea.additionalInformation?.remark}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.active ? "Yes" : "No"}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.additionalInformation?.isHot ? "Yes" : "No"}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.additionalInformation?.hasEvCharging ? "Yes" : "No"}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.stripeProductId}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.discountPercentage?.annually}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.discountPercentage?.halfYearly}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.discountPercentage?.quarterly}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.discountPercentage?.monthly}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.discountPercentage?.biWeekly}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.discountPercentage?.weekly}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.additionalInformation?.management ? `${parkingArea.additionalInformation.management.contactName} (${parkingArea.additionalInformation.management.contactNumber})` : '' }</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.location?.latitude}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{parkingArea.location?.longitude}</TableCell>
                      <TableCell align="left" className={styles.tableCell110}>{Moment(parkingArea.createdAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={27} align="center">
                      <p>No Parking Areas</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={4}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.addModalOpen && <AddParkingAreaModal />}
      {state.editModalOpen && <EditParkingAreaModal />}
      {state.deleteModalOpen && <DeleteParkingAreaModal />}
    </div>
  );
}