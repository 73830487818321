import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Paper, Grid, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import moment from "moment";

import {
  handleUpdateEnquiry,
  selectState,
  showEditModal,
} from "./buildingEnquiriesSlice";
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

const EditEnquiryModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();

  const enquiry = state.selectedEnquiry;
  const [startDate, setStartDate] = useState(enquiry.startDate);
  const [estBudget, setEstBudget] = useState(enquiry.estBudget);
  const [remark, setRemark] = useState(enquiry.remark);
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const submit = async () => {
    try {
      let formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      const payload = {
        ...enquiry,
        startDate: formattedStartDate,
        estBudget,
        remark
      };

      await withDisable(() => dispatch(handleUpdateEnquiry(state.selectedId, payload)));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="add-renting-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showEditModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Edit Enquiry</p>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="startDate"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
                margin="normal"
                variant="outlined"
                type="date"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="estBudget"
                label="Estimated Budget (MYR)"
                onChange={(e) => setEstBudget(e.target.value)}
                value={estBudget}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="remark"
                label="Remarks"
                onChange={(e) => setRemark(e.target.value)}
                value={remark}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                disabled={isDisabled}
                className={`bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md ${
                  isDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                }`}
              >
                Update
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default EditEnquiryModal;
