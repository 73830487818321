import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeAdminTopUpModal, handleGetUsers } from './userSlice';
import { Modal, Paper, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import styles from './User.module.css';
import { getUserWallet, topUpWallet } from '../../api/walletAPI';
import useDisableWhileRequesting from "./../../hooks/useDisableWhileRequesting";

export function AdminTopUp(props) {
  const { selectedUserId } = props;
  const dispatch = useDispatch();
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const [init, setInit] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [topUpAmount, setTopUpAmount] = useState(0);

  useEffect(() => {
    const handleGetWalletByUserId = async () => {
      try {
        const walletData = await getUserWallet(selectedUserId);
        setWallet(walletData);
      } catch (error) {
        alert(error?.response?.data?.message || error.message);
      }
    }

    if (!init && selectedUserId) {
      handleGetWalletByUserId();
      setInit(true);
    }
  }, [init, selectedUserId]);

  const submit = async () => {
    const topUpAmountInCent = Number((topUpAmount * 100).toFixed(0));
    try {
      let walletPayload = {
        method: "BANK_IN",
        topUpAmount: topUpAmountInCent,
        detail: `Admin Top Up RM${topUpAmount}`
      }

      await withDisable(() => topUpWallet(wallet.id, walletPayload));
      dispatch(handleGetUsers());
      dispatch(closeAdminTopUpModal());
    } catch (error) {
      alert(error);
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="admin-topup-modal"
      className={styles.modal}
    >
      <Paper className={`${styles.modalContainer} scrollbar-hidden`}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeAdminTopUpModal())} />
          <h3 className={styles.subtitle}>Manual Top Up To User</h3>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <p>Top Up Amount</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="topUpAmount"
                  name="topUpAmount"
                  value={topUpAmount}
                  onChange={e => setTopUpAmount(e.target.value)}
                  placeholder="Amount"
                  type="number"
                   InputProps={{
                       onWheel: (e) => e.target.blur()
                   }}
                  className={styles.textInput}
                />
              </Paper>
              <Grid item xs={12} className="flex justify-center">
                <button
                  onClick={submit}
                  disabled={isDisabled}
                  className={`bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md ${
                    isDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                  }`}
                >
                  Submit
                </button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}