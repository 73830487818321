import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  TextField,
  Switch,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Typography,
  Button
} from "@mui/material";
import { Close } from "@mui/icons-material";
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

import GoogleAutocomplete from "../../../components/GoogleAutocomplete/GoogleAutocomplete";
import { handleAddParkingArea, showAddModal } from "./parkingAreaSlice";
import useToasts from "../../../hooks/useToasts";

const AddParkingAreaModal = () => {
  const dispatch = useDispatch();
  const { toastError } = useToasts();
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [overridePriceAvg, setOverridePriceAvg] = useState("");
  const [annualDiscount, setAnnualDiscount] = useState("");
  const [halfYearlyDiscount, setHalfYearlyDiscount] = useState("");
  const [quarterlyDiscount, setQuarterlyDiscount] = useState("");
  const [monthlyDiscount, setMonthlyDiscount] = useState("");
  const [biWeeklyDiscount, setBiWeeklyDiscount] = useState("");
  const [weeklyDiscount, setWeeklyDiscount] = useState("");
  const [active, setActive] = useState(false);
  const [isHot, setIsHot] = useState(false);
  const [hasEvCharging, setHasEvCharging] = useState(false);
  const [currency, setCurrency] = useState("MYR");
  const [country, setCountry] = useState("Malaysia");
  const [remark, setRemark] = useState("");
  const [addImage, setAddImage] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [formData, setFormData] = useState("");
  const [thingsToKnow, setThingsToKnow] = useState([]);

  const calculateDiscountedPrice = (percentage) => {
    if (percentage !== null) {
      return ((100 - percentage) / 100) * overridePriceAvg;
    } else {
      return overridePriceAvg;
    }
  };

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];
    if (file !== undefined) {
      const formData = new FormData();
      formData.append("file", file);

      setAddImage(true);
      setImageFile(file);
      setFormData(formData);
    }
  };

  const validateFields = () => {
    if (!address || !latitude || !longitude || !country || !currency || 
      ((annualDiscount || halfYearlyDiscount || quarterlyDiscount || monthlyDiscount || biWeeklyDiscount || weeklyDiscount) 
      && !(annualDiscount && halfYearlyDiscount && quarterlyDiscount && monthlyDiscount && biWeeklyDiscount && weeklyDiscount))) {
      toastError("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  const submit = async () => {
    if (validateFields()) {
      try {
        const payload = {
          name,
          address,
          location: {
            latitude: latitude,
            longitude: longitude,
          },
          ...(overridePriceAvg && {
            overridePrice: {
                avg: overridePriceAvg * 100,
                min: overridePriceAvg * 100,
                max: overridePriceAvg * 100
            }
          }),
          ...((annualDiscount && halfYearlyDiscount && quarterlyDiscount && monthlyDiscount && biWeeklyDiscount && weeklyDiscount) && {
            discountPercentage: {
              annually: annualDiscount,
              halfYearly: halfYearlyDiscount,
              quarterly: quarterlyDiscount,
              monthly: monthlyDiscount,
              biWeekly: biWeeklyDiscount,
              weekly: weeklyDiscount
            },
          }),
          active,
          additionalInformation: {
            description,
            isHot,
            hasEvCharging,
            remark,
            thingsToKnow
          },
          currency,
          country
        };
        await withDisable(() => dispatch(handleAddParkingArea(payload, addImage, formData)));
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const handleThingsToKnowTitleChange = (titleIndex, value) => {
    const updatedThingsToKnow = [...thingsToKnow];
    updatedThingsToKnow[titleIndex].title = value;
    setThingsToKnow(updatedThingsToKnow);
  };

  const handleThingsToKnowDetailChange = (titleIndex, detailIndex, value) => {
    const updatedThingsToKnow = [...thingsToKnow];
    updatedThingsToKnow[titleIndex].details[detailIndex] = value;
    setThingsToKnow(updatedThingsToKnow);
  };

  const addNewDetail = (titleIndex) => {
    const updatedThingsToKnow = [...thingsToKnow];
    updatedThingsToKnow[titleIndex].details.push("");
    setThingsToKnow(updatedThingsToKnow);
  };

  const removeDetail = (titleIndex, detailIndex) => {
    const updatedThingsToKnow = [...thingsToKnow];
    updatedThingsToKnow[titleIndex].details.splice(detailIndex, 1);
    setThingsToKnow(updatedThingsToKnow);
  };

  const addNewTitle = () => {
    setThingsToKnow([...thingsToKnow, { title: "", details: [""] }]);
  };

  const removeTitle = (titleIndex) => {
    const updatedThingsToKnow = thingsToKnow.filter((_, index) => index !== titleIndex);
    setThingsToKnow(updatedThingsToKnow);
  };

  return (
    <Modal
      open={true}
      aria-labelledby="add-parking-area-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showAddModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Add Parking Area</p>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <p className="mb-[3px] text-[rgba(0,0,0,0.6)] text-[12px] font-semibold">
                Address
              </p>
              <GoogleAutocomplete
                className="w-full h-[56px] mb-[8px] text-[14px] font-bold rounded-[4px] border border-[#ccc]"
                id="address"
                name="address"
                placeholder="Address"
                value={address}
                onPlaceChanged={(place) => {
                  if (place.formatted_address.includes("Malaysia")) {
                    setCurrency("MYR");
                    setCountry("Malaysia");
                  } else if (place.formatted_address.includes("Hong Kong")) {
                    setCurrency("HKD");
                    setCountry("HongKong")
                  } else if (place.formatted_address.includes("Singapore")) {
                    setCurrency("SGD");
                    setCountry("Singapore");
                  }

                  setAddress(
                    `${place.name}${
                      place.formatted_address
                        ? ", " + place.formatted_address
                        : ""
                    }`
                  );
                  setLatitude(place.geometry.location.lat());
                  setLongitude(place.geometry.location.lng());
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="relative rounded my-4">
                <label
                  className="relative block p-0 max-w-[calc(133%-24px)] left-0 top-0 z-[1] pointer-events-auto select-none transform origin-top-left translate-x-[14px] translate-y-[-9px] scale-75 transition-[color,transform,max-width] duration-200 ease-[cubic-bezier(0.0,0,0.2,1)] text-ellipsis overflow-hidden whitespace-nowrap text-black/60 text-base font-semibold leading-[1.4375em] tracking-[0.00938em]"
                  data-shrink="true"
                  for="description"
                  id="description-label"
                >
                  Description
                </label>
                <textarea
                  rows="3"
                  id="description"
                  label="Description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  className="w-full h-full border-none text-sm font-medium"
                  style={{ boxShadow: 'none' }}
                />
                <fieldset aria-hidden="true" className="text-left absolute bottom-0 right-0 top-[-5px] left-0 m-0 px-2 pointer-events-none rounded border-solid border overflow-hidden min-w-0 border-[rgba(0,0,0,0.23)]"><legend className="border-gray-300 rounded float-none w-auto overflow-hidden block p-0 h-[11px] text-[0.75em] invisible max-w-full transition-max-width duration-[100ms] ease-[cubic-bezier(0.0, 0, 0.2, 1)] delay-[50ms] whitespace-nowrap w-[76px]"><span>Description</span></legend></fieldset>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="latitude"
                label="Latitude"
                onChange={(e) => setLatitude(e.target.value)}
                value={latitude}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="longitude"
                label="Longitude"
                onChange={(e) => setLongitude(e.target.value)}
                value={longitude}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="currency">Currency</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setCurrency(e.target.value)}
                  value={currency}
                  input={
                    <OutlinedInput
                      name="currency"
                      id="currency"
                      label="Currency"
                    />
                  }
                >
                  <MenuItem key="MYR" value="MYR">
                    MYR
                  </MenuItem>
                  <MenuItem key="SGD" value="SGD">
                    SGD
                  </MenuItem>
                  <MenuItem key="HKD" value="HKD">
                    HKD
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="min"
                label="Average Price"
                onChange={(e) => setOverridePriceAvg(e.target.value)}
                value={overridePriceAvg ?? ""}
                margin="normal"
                variant="outlined"
                type="number"
                InputProps={{
                    onWheel: (e) => e.target.blur()
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="annually"
                label="Annual Discount (13)"
                onChange={(e) => setAnnualDiscount(e.target.value)}
                value={annualDiscount ?? ""}
                margin="normal"
                variant="outlined"
                type="number"
                InputProps={{
                    onWheel: (e) => e.target.blur()
                }}
                fullWidth
                required
              />
              <p className="mt-[-3px] text-[#DE4D65] text-[12px]">
                Annual Price: {calculateDiscountedPrice(annualDiscount)}
              </p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="halfYearly"
                label="Half-Yearly Discount (8)"
                onChange={(e) => setHalfYearlyDiscount(e.target.value)}
                value={halfYearlyDiscount ?? ""}
                margin="normal"
                variant="outlined"
                type="number"
                InputProps={{
                    onWheel: (e) => e.target.blur()
                }}
                fullWidth
                required
              />
              <p className="mt-[-3px] text-[#DE4D65] text-[12px]">
                Half-Yearly Price:{" "}
                {calculateDiscountedPrice(halfYearlyDiscount)}
              </p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="quarterly"
                label="Quarterly Discount (5)"
                onChange={(e) => setQuarterlyDiscount(e.target.value)}
                value={quarterlyDiscount ?? ""}
                margin="normal"
                variant="outlined"
                type="number"
                InputProps={{
                    onWheel: (e) => e.target.blur()
                }}
                fullWidth
                required
              />
              <p className="mt-[-3px] text-[#DE4D65] text-[12px]">
                Quarterly Price: {calculateDiscountedPrice(quarterlyDiscount)}
              </p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="monthly"
                label="Monthly Discount"
                onChange={(e) => setMonthlyDiscount(e.target.value)}
                value={monthlyDiscount ?? ""}
                margin="normal"
                variant="outlined"
                type="number"
                InputProps={{
                    onWheel: (e) => e.target.blur()
                }}
                fullWidth
                required
              />
              <p className="mt-[-3px] mb-[5px] text-[#DE4D65] text-[12px]">
                Monthly Price: {calculateDiscountedPrice(monthlyDiscount)}
              </p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="biWeekly"
                label="Bi-Weekly Discount"
                onChange={(e) => setBiWeeklyDiscount(e.target.value)}
                value={biWeeklyDiscount ?? ""}
                margin="normal"
                variant="outlined"
                type="number"
                InputProps={{
                    onWheel: (e) => e.target.blur()
                }}
                fullWidth
                required
              />
              <p className="mt-[-3px] mb-[5px] text-[#DE4D65] text-[12px]">
                Bi-Weekly Price: {calculateDiscountedPrice(biWeeklyDiscount)}
              </p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="weekly"
                label="Weekly Discount"
                onChange={(e) => setWeeklyDiscount(e.target.value)}
                value={weeklyDiscount ?? ""}
                margin="normal"
                variant="outlined"
                type="number"
                InputProps={{
                    onWheel: (e) => e.target.blur()
                }}
                fullWidth
                required
              />
              <p className="mt-[-3px] mb-[5px] text-[#DE4D65] text-[12px]">
                Weekly Price: {calculateDiscountedPrice(weeklyDiscount)}
              </p>
            </Grid>
            {(annualDiscount || halfYearlyDiscount || quarterlyDiscount || monthlyDiscount || biWeeklyDiscount || weeklyDiscount) 
            && !(annualDiscount && halfYearlyDiscount && quarterlyDiscount && monthlyDiscount && biWeeklyDiscount && weeklyDiscount)  && (
            <Grid item xs={12} mt={1}>
              <Typography variant="body1" color="error">
                All interval discounts cannot be left empty if any one of them is set.
              </Typography>
            </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                id="remark"
                label="Remark"
                onChange={(e) => setRemark(e.target.value)}
                value={remark}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography variant="body1">
                Things To Know
              </Typography>
            </Grid>
            {thingsToKnow?.map((thing, titleIndex) => (
              <React.Fragment key={`title-${titleIndex}`}>
                <Grid item xs={12} sm={7} alignItems="center">
                  <TextField
                    id={`thingsToKnowTitle-${titleIndex}`}
                    label={`Title ${titleIndex + 1}`}
                    onChange={(e) => handleThingsToKnowTitleChange(titleIndex, e.target.value)}
                    value={thing.title}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2} container alignItems="center" justifyContent="flex-start">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => removeTitle(titleIndex)}
                    style={{ marginLeft: 0 }}
                    fullWidth
                  >
                    Remove
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3} container alignItems="center" justifyContent="flex-start">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => addNewDetail(titleIndex)}
                    fullWidth
                  >
                    Add Detail
                  </Button>
                </Grid>
                {thing?.details?.map((detail, detailIndex) => (
                  <React.Fragment key={`detail-${titleIndex}-${detailIndex}`}>
                    <Grid item xs={12} sm={10} alignItems="center">
                      <TextField
                        id={`thingsToKnowTitle-${titleIndex}Detail-${detailIndex}`}
                        label={`Detail ${detailIndex + 1}`}
                        onChange={(e) => handleThingsToKnowDetailChange(titleIndex, detailIndex, e.target.value)}
                        value={detail}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} container alignItems="center" justifyContent="flex-start">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => removeDetail(titleIndex, detailIndex)}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
            <Grid item xs={12} mt={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={addNewTitle}
              >
                Add Title
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} mt={1}>
              <Grid component="label" container alignItems="center">
                <Grid item>Inactive</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />
                </Grid>
                <Grid item>Active</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} mt={1}>
              <Grid component="label" container alignItems="center">
                <Grid item>Non-hot</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={isHot}
                    onChange={(e) => setIsHot(e.target.checked)}
                  />
                </Grid>
                <Grid item>Hot</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} mt={1}>
              <Grid component="label" container alignItems="center">
                <Grid item>Not EV</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={hasEvCharging}
                    onChange={(e) => setHasEvCharging(e.target.checked)}
                  />
                </Grid>
                <Grid item>EV Charging</Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} mt={3}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => handleUploadImage(e)}
                id="raised-button-file"
                type="file"
              />
              <label htmlFor="raised-button-file">
                <span
                  class="raised-button"
                  className="bg-[#D9D9D9] px-[30px] py-[8px] text-[14px] font-medium rounded-[5px] shadow-md cursor-pointer"
                >
                  Choose A File
                </span>
                <span className="ml-[20px] text-[12px]">
                  {(addImage && imageFile?.name) ?? "No file selected"}
                </span>
              </label>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                disabled={isDisabled}
                className={`bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md ${
                  isDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                }`}
              >
                Confirm
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default AddParkingAreaModal;
