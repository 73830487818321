import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Paper, Grid, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

import {
  handleUpdateInsurance,
  selectState,
  showEditModal,
} from "./insuranceSlice";

const EditInsuranceModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();

  const subscriber = state.selectedSubscriber;
  const [icNumber, setIcNumber] = useState(subscriber.identificationCard);
  const [passportNumber, setPassportNumber] = useState(subscriber.passportNumber);
  const [occupation, setOccupation]=  useState(subscriber.occupation);
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const submit = async () => {
    try {
      const payload = {
        customerId: state.selectedId,
        identificationCard: icNumber,
        passportNumber,
        occupation
      };

      await withDisable(() => dispatch(handleUpdateInsurance(payload)));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="add-renting-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showEditModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Edit Insurance Details</p>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="icNumber"
                label="IC Number"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setIcNumber(e.target.value)}
                value={icNumber}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="passportNumber"
                label="Passport Number"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setPassportNumber(e.target.value)}
                value={passportNumber}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="occupation"
                label="Occupation"
                onChange={(e) => setOccupation(e.target.value)}
                value={occupation}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                disabled={isDisabled}
                className={`bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md ${
                  isDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                }`}
              >
                Update
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default EditInsuranceModal;
